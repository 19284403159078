import { StyledSvg } from "baseui/icon";
import { omit } from "lodash";
import React from "react";

export const ChevronPixelatedIcon = (props: React.ComponentProps<typeof StyledSvg> & { direction: "up" | "down" | "left" | "right" }) => {
  let rotation = 0;
  if (props.direction == "down") {
    rotation = 90;
  } else if (props.direction == "left") {
    rotation = 180;
  } else if (props.direction == "up") {
    rotation = -90;
  }

  return (
    <StyledSvg viewBox="0 0 15 15" {...omit(props, "direction")}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.83333 1.66602H4.5V3.99935H6.83333V1.66602ZM6.83594 4H9.16927V6.33203H11.4974V8.66536H9.16406V6.33333H6.83594V4ZM6.83594 8.66602H9.16927V10.9993H6.83594V8.66602ZM6.83333 11H4.5V13.3333H6.83333V11Z"
        fill="#545454"
        style={{
          transform: `rotate3d(0, 0, 1, ${rotation}deg)`,
        }}
      />
    </StyledSvg>
  );
};
