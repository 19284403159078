import type { Instance } from "@gadgetinc/mobx-quick-tree";
import { Banner, BannerKind, Row, paddingUtil } from "@gadgetinc/widgets";
import { proseFamily } from "@gadgetinc/widgets/src/AuthTheme";
import { ChevronPixelatedIcon } from "@gadgetinc/widgets/src/icons/ChevronPixelatedIcon";
import { styled, useStyletron } from "baseui";
import type { ComponentProps, ReactNode } from "react";
import React, { createContext, useContext, useMemo } from "react";
import type { FetchData } from "use-http";
import { CachePolicies, useFetch } from "use-http";
import { SignUpOrSignInForm, maybeJoinStringArray } from "../../client-state-trees/auth/SignUpOrSignInForm";
import { Turnstile, TurnstileProps } from "@marsidev/react-turnstile";
import { AuthConfig } from "./AuthConfigProvider";

const noteStyles = {
  fontFamily: proseFamily,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  minHeight: "50px",
  overflow: "hidden",
};
const fadeIn = {
  opacity: 1,
  transition: "opacity 0.5s ease-in-out",
};
const fadeOut = {
  height: 0,
  minHeight: 0,
  opacity: 0,
  padding: 0,
};

type NoteProps = {
  loading?: boolean;
};

export const NegativeNote = (props: ComponentProps<typeof Banner> & NoteProps) => {
  const [_, $theme] = useStyletron();
  return (
    <Banner
      noIcon
      kind={BannerKind.negative}
      $style={{
        ...noteStyles,
        ...(props.loading ? fadeOut : fadeIn),
        color: $theme.colors.red600,
      }}
      {...props}
    />
  );
};
export const WarningNote = (props: ComponentProps<typeof Banner> & NoteProps) => {
  const [_, $theme] = useStyletron();
  return (
    <Banner
      noIcon
      kind={BannerKind.warning}
      $style={{
        ...noteStyles,
        ...(props.loading ? fadeOut : fadeIn),
        color: $theme.colors.warning700,
      }}
      {...props}
    />
  );
};
export const PositiveNote = (props: ComponentProps<typeof Banner> & NoteProps) => {
  const [_, $theme] = useStyletron();
  return (
    <Banner
      noIcon
      kind={BannerKind.positive}
      $style={{
        ...noteStyles,
        ...(props.loading ? fadeOut : fadeIn),
        color: $theme.colors.green700,
      }}
      {...props}
    />
  );
};

export const StyledParagraph = styled("p", ({ $theme }) => ({
  fontFamily: proseFamily,
  fontWeight: 400,
  fontSize: $theme.sizing.scale600,
  lineHeight: "150%",
}));

export const StyledBold = styled("strong", ({ $theme }) => ({
  fontFamily: proseFamily,
  fontWeight: 700,
  fontSize: $theme.sizing.scale700,
  lineHeight: "150%",
  color: $theme.colors.black,
}));

export const StyledParagraphSmall = styled("p", ({ $theme }) => ({
  fontFamily: proseFamily,
  color: $theme.colors.primary600,
  fontWeight: 400,
  fontSize: $theme.sizing.scale500,
  lineHeight: "150%",
}));

export const FeatureRow = (props: { children: ReactNode }) => {
  const [_, $theme] = useStyletron();
  return (
    <Row $gap={$theme.sizing.scale300} $style={{ alignItems: "center", color: $theme.colors.primary500 }}>
      <ChevronPixelatedIcon $size="14px" />
      <StyledParagraph $style={{}}>{props.children}</StyledParagraph>
    </Row>
  );
};

export type LoginPageProps = {
  presentAsSignup?: boolean;
  sentVerificationEmail?: boolean;
  emailLogin?: boolean;
  error?: string | string[];
  warning?: string | string[];
  message?: string | string[];
  email?: string;
  token?: string;
} & { config: AuthConfig };

type LoginContextProps = {
  checkEmail: FetchData;
  createUser: FetchData;
  resendLink: FetchData;
  requestReset: FetchData;
  signIn: FetchData;
  resetPassword: FetchData;
  confirmEmail: FetchData;
  form: Instance<typeof SignUpOrSignInForm>;
};

export const LoginContext = createContext<LoginContextProps>({} as LoginContextProps);

export const useLogin = () => useContext(LoginContext);

export const LoginContextProvider = (props: { children: ReactNode } & LoginPageProps) => {
  const checkEmail = useFetch("/auth/api/check-email", { cachePolicy: CachePolicies.NETWORK_ONLY }).post;
  const createUser = useFetch("/auth/api/create-user", { cachePolicy: CachePolicies.NETWORK_ONLY }).post;
  const resendLink = useFetch("/auth/api/resend-verification", { cachePolicy: CachePolicies.NETWORK_ONLY }).post;
  const requestReset = useFetch("/auth/api/request-reset", { cachePolicy: CachePolicies.NETWORK_ONLY }).post;
  const signIn = useFetch("/auth/local", { cachePolicy: CachePolicies.NETWORK_ONLY }).post;
  const resetPassword = useFetch("/auth/api/reset", { cachePolicy: CachePolicies.NETWORK_ONLY }).post;
  const confirmEmail = useFetch("/auth/api/confirm-email", { cachePolicy: CachePolicies.NETWORK_ONLY }).post;

  const form = useMemo(() => {
    return SignUpOrSignInForm.create({
      signup: !!props.presentAsSignup,
      emailSent: !!props.sentVerificationEmail,
      error: maybeJoinStringArray(props.error),
      warning: maybeJoinStringArray(props.warning),
      message: maybeJoinStringArray(props.message),
      email: props.email,
      token: props.token,
      userVerified: props.emailLogin,
    });
    // NOTE: These props never change, because they come from the route
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginContext.Provider
      value={{
        checkEmail,
        createUser,
        resendLink,
        requestReset,
        signIn,
        resetPassword,
        confirmEmail,
        form,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};
